body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Pretendard Regular";
  src: url("/public/Pretendard/public/static/Pretendard-Regular.otf");
}

@font-face {
  font-family: "Pretendard Bold";
  src: url("/public/Pretendard/public/static/Pretendard-Bold.otf");
}

@font-face {
  font-family: "Pretendard Extra Bold";
  src: url("/public/Pretendard/public/static/Pretendard-ExtraBold.otf");
}

@font-face {
  font-family: "Pretendard Extra Light";
  src: url("/public/Pretendard/public/static/Pretendard-ExtraLight.otf");
}

@font-face {
  font-family: "Pretendard Semi Bold";
  src: url("/public/Pretendard/public/static/Pretendard-SemiBold.otf");
}

span {
  font-family: "Pretendard Semi Bold";
}

span {
  font-family: "Pretendard Regular";
}

h1 {
  font-family: "Pretendard Extra Bold";
}

h2 {
  font-family: "Pretendard Bold";
}

h6 {
  font-family: "Pretendard bold";
}

p {
  font-family: "Pretendard Regular Bold";
}

h3 {
  font-family: "Pretendard Extra Light";
}
h5 {
  font-family: "Pretendard Bold";
}
